import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  private user: BehaviorSubject<any> = new BehaviorSubject({});
  private configStore : BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private httpService : HttpService
    
  ) { }

  setUser(user){
    localStorage.setItem('user', JSON.stringify(user))
    this.user.next(user)
  }

  getUser(){
    let user = JSON.parse(localStorage.getItem('user'))
    this.user.next(user)
    return this.user
  }

  getListPage(page: number = 1,search:any  ="") : Promise<any> {

    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('search', String(search));

    return this.httpService.get(`admin/stores/list`,params);
  }


  updateStoreStatusById(id: string|any, status: string) : Promise<any> {
    return this.httpService.put(`admin/stores/${id}/status`, {status: status});
  }

  updateMyStore(store: object) : Promise<any> {
    return this.httpService.put(`admin/stores/updateMyStore`, store);
  }
  
  updateMyHeaderStore(store: object) : Promise<any> {
    return this.httpService.put(`admin/stores/updateOnlyMyStore`,store);
  }

  updateStore(id: string, store: object) : Promise<any> {
    return this.httpService.put(`admin/stores/${id}`, store);
  }


  getStoreByUsc(usc: string ) : Promise<any> {
    return this.httpService.get(`admin/stores/${usc}/detail`);
  }

  getMyStore() : Promise<any> {
    return this.httpService.get(`admin/stores/mystore`);
  }

  getCities() : Promise<any> {
    return new Promise((resolve, reject) => {
        return this.http.get(`http://geo.byflexy.com/api/v1/list?alpha_3=BRA`,{
          headers:{
            "Authorization":"l3b1cabd99f1901da0585ka31a7bafe635df9a74070701381f159437100f204b1"
          }
        })
          .subscribe((res: any) => {
            if (res.state == 'success') {
              resolve({ response: res.response.data, msg: 'success' });
            }
            else {
              reject({ msg: res.msg });
            }
          }, (err): any => {
            reject({ msg: err.error.error });
          });
    });
  }

  getStoreUsersByUsc(usc: string ) : Promise<any> {
    return this.httpService.get(`admin/stores/${usc}/users`);
  }

  addUser(usc: string, user: object) : Promise<any> {
    return this.httpService.put(`admin/stores/${usc}/addUser`, user);
  }

  add(store: object) : Promise<any> {
    return this.httpService.post(`admin/stores`, store);
  }

  addPublic(store: object) : Promise<any> {
    return this.httpService.post(`stores`, store);
  }

  getListSelect() : Promise<any> {
    return this.httpService.get(`admin/stores/listSelect`);
  }

  deleteStore(data:object): Promise<any> {
    return this.httpService.delete('admin/stores/delete', data)
  }

  updatePersonalized(data:object){
    return this.httpService.post('admin/deliveries/updatepersonalized', data)
  }

  updatePersonalizedPayment(data:object){
    return this.httpService.post('admin/payments/updatecustompayment', data)
  }

  getAllConfig(){
    let config = JSON.parse(localStorage.getItem('config_store'))
    this.configStore.next(config)
    return this.configStore
  }


  getConf(id_store:string){
    let params : HttpParams = new HttpParams()
    .append('id_store', String(id_store))
    this.httpService.get('configurations/forstore', params)
    .then(res => {
      localStorage.setItem('config_store', JSON.stringify(res.response))
      this.configStore.next(res.response)
    }).catch(err => {
      console.log(err)
    })
  }

  getDeliveriesFromStore(id:string){
    let params: HttpParams = new HttpParams();
    params = params.append('id', id);
    return this.httpService.get(`delivery/getinputs`, params)
  }

  setInfoUser(user){
    localStorage.setItem('user', JSON.stringify(user))
  }

  qrUpdate(data:any){
    return this.httpService.post(`admin/stores/updateqr`, data)
  }


}
