export const environment = {
  production: true,

  API_URL_IMAGE: "https://api.oseumenu.com/api/v2/admin/files/",
  API_URL: "https://api.oseumenu.com/api/v2/",
  API_URL_STORAGE: "https://api.oseumenu.com/storage/exports",

 CLIENT: 'test',
 API_URL_WHATSAPP_SOCKET: "http://whatsapp.byflexy.com:33444/",
 API_URL_WHATSAPP_CONFIG: "http://whatsapp.byflexy.com/api/v1/getChatCredentials/",
 DEBUG_WHATSAPP_SERVICE: true,
 PUBLIC_KEY: 'BI8B2PVUBVzhZxAOMLTJJkDIUZcmChFCZcP7UP4wOXdwurIXxWykVn0y3Knj_QswD1fCKkN5adGA9IuwCfcl0Sg',
 PRIVATE_KEY: 'cR7rVJQMfb-Syw6wyefRKCRKIIyR-EnZRM8q7Zscfhw'
};
