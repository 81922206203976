import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output,Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { CategoryService } from 'src/app/service/categorias/category.service';
import { ProductosService } from 'src/app/service/productos/productos.service';
import { StorageService } from 'src/app/service/storage/storage.service';

@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.html',
  styles: [
  ]
})
export class ListCategoriesComponent implements OnInit {
  current_page: number = 1;
  list : any[] = [{
    id: 'none',
    name: 'None'
  }];
  previousPage: number = 0;
  listFinish = false;
  categoriesSelected :any[] = []
  @Output() filterCatagories: EventEmitter<any> = new EventEmitter();
  @Input() ctgId:string = ''

  private subscriptions: Array<Subscription> = [];

  role:string = ''
  constructor(
    private categoryService: CategoryService,
    private cd: ChangeDetectorRef,
    private productService: ProductosService,
    private storage: StorageService,
  ) { this.getRole() }

  ngOnInit(): void {
    this.loadCategories()
  }

  getRole(){
    this.subscriptions.push(
      this.storage.getRole()
      .subscribe((rol:string) => rol !== '' && (this.role = JSON.parse(rol)) )
    )
  }

  loadCategories(){
    if(this.role === 'store'){
      this.categoryService.getListPage(this.current_page, 'active', '')
      .then(res => {
        
        let categories:any[] = res.response.data
        let hasSub = categories.some(ctg => ctg.sub_categories.length > 0)
        if(hasSub){
          this.list = [...this.list, ...categories]
          categories.map(ctg => {
            if(ctg.sub_categories.length > 0 ){
              ctg.isParent = true
              ctg.sub_categories.map(item => {
                this.list.push(item)
              })
            }
          })
        }else{
        this.list = [...this.list, ...res.response.data]
        }
        this.cd.detectChanges()
      
      })
    }else{
      this.categoryService.getListPageAdmin(this.current_page, 'active', '')
      .then(res => {
        let categories:any[] = res.response.data
        let hasSub = categories.some(ctg => ctg.sub_categories.length > 0)
        if(hasSub){
          this.list = [...this.list, ...categories]
          categories.map(ctg => {
            if(ctg.sub_categories.length > 0 ){
              ctg.isParent = true
              ctg.sub_categories.map(item => {
                this.list.push(item)
              })
            }
          })
        }else{
          this.list = [...this.list, ...res.response.data]
        }
        this.cd.detectChanges()
      })
    }
  }


  findNext(){
    if(!this.listFinish){
      if(this.role === 'store'){
        let newPage = this.current_page + 1
        this.categoryService.getListPage(newPage, 'active', '')
        .then(res => {
          this.list = [...this.list, ...res.response.data]
          this.cd.detectChanges()
          if(res.response.current_page === res.response.last_page ) this.listFinish = true
          else this.current_page = newPage
        })
      }else{
        let newPage = this.current_page + 1
        this.categoryService.getListPageAdmin(newPage, 'active', '')
        .then(res => {
          this.list = [...this.list, ...res.response.data]
          this.cd.detectChanges()
          if(res.response.current_page === res.response.last_page ) this.listFinish = true
          else this.current_page = newPage
        })
      }
    }
  }

  scrollTablet(){
    this.findNext()
  }

  change(event){
    this.filterCatagories.emit(event.id)
  }

}
