import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleClassService {

  public comprimida: boolean = false;
  private buttonActive: BehaviorSubject<any> = new BehaviorSubject('');

  constructor(){
    // document.documentElement.clientHeight <= 600 ? (this.comprimida = true) : ( this.comprimida = false )
  }

  public toggle(): void {
      this.comprimida = !this.comprimida;
  }
  

  setButtonActive(url){
    this.buttonActive.next(url)
  }

  getButtonActive(){
    return this.buttonActive
  }

}
