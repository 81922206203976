import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from "@angular/common"
import { StorageService } from 'src/app/service/storage/storage.service';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  routes:any[] = []
  role:string = ''
  nameUrl:string = ''
  constructor(
    private location: Location,
    private storageService: StorageService,
    private router: Router
    ) {

    this.storageService.getRole().subscribe( (rol:string) => {
      if(rol){
        let rolParsed = JSON.parse(rol)
        this.role = rolParsed
        if(rolParsed === 'root') this.nameUrl = 'Tiendas'
        if(rolParsed === 'support') this.nameUrl = 'Soporte'
        if(rolParsed === 'store') this.nameUrl = 'Mi Tienda'
      }
    })

    this.location.onUrlChange(url =>  this.routes = url.split('/') )
  }

  ngOnInit(): void {

  }

  getRoute(route:string){

      if(route === '/'){
        if( this.role === 'root' ) this.router.navigate(['/tiendas'])
        if( this.role === 'store' ) this.router.navigate(['/tiendas/updateStore'])
        if( this.role === 'support' ) this.router.navigate(['/support/stores'])
      }else{
        this.router.navigate([`${route}`])
      }
  }

}
