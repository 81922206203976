import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  private categoriesSelected : BehaviorSubject<any[]> = new BehaviorSubject([])
  private tagSelected : BehaviorSubject<any[]> = new BehaviorSubject([])
  private artistSelected : BehaviorSubject<any[]> = new BehaviorSubject([])
  private toppingSelected: BehaviorSubject<any[]> = new BehaviorSubject([])
  private productselected : BehaviorSubject<any> = new BehaviorSubject({})

  private productsToShare : BehaviorSubject<any> = new BehaviorSubject([])
  private variations_selected : BehaviorSubject<any> = new BehaviorSubject([])

  private dataProdForm : BehaviorSubject<any> = new BehaviorSubject({})

  private filterSearch : BehaviorSubject<any> = new BehaviorSubject({})
  private typeProds : BehaviorSubject<string> = new BehaviorSubject('')


  constructor(
    private httpService: HttpService,
  ) { }

  setDataProdForm(form){
    this.dataProdForm.next(form)
  }

  getDataProdForm(): Observable<any>{
    return this.dataProdForm
  }


  setTypeProds(type){ this.typeProds.next(type) }

  getTypeProds(): Observable<any>{ return this.typeProds }

  setproductsToShare(product){ this.productsToShare.next(product) }

  getproductsToShare(): Observable<any>{ return this.productsToShare }

  setvariations(variation){ this.variations_selected.next(variation) }

  getvariation(): Observable<any>{ return this.variations_selected }

  setproduct(product){
    this.productselected.next(product)
   }


   setFilterSearch(filterSearchData:any){
    this.filterSearch.next(filterSearchData)
   }

   getFilterSearch(){
    return this.filterSearch
   }


  getproduct(): Observable<any>{
    if(JSON.parse(localStorage.getItem('product_edit'))){
      this.productselected.next(JSON.parse(localStorage.getItem('product_edit')))
      return this.productselected
    }
    return this.productselected
  }

  setCategories(categories){
    this.categoriesSelected.next(categories)
  }

  getCategories(){
    return this.categoriesSelected
  }
  setTags(tags){
    this.tagSelected.next(tags)
  }

  getTags(){
    return this.tagSelected
  }
  setArtist(artist){
    this.artistSelected.next(artist)
  }
  getArtist(){
    return this.artistSelected
  }
  
  setToppings(topping){
    this.toppingSelected.next(topping)
  }
  getToppings(){
    return this.toppingSelected
  }

  add(product: object) : Promise<any> {
    return this.httpService.post(`admin/products`, product);
  }

  addProductProperties(product: object) : Promise<any>{
    return this.httpService.post('admin/properties/createproduct', product)
  }

  updateProductPrice(data: object) : Promise<any>{
    return this.httpService.post('admin/products/updateprice', data)
  }

  updateProdProperties(product:object,id_product:any): Promise<any>{
    return this.httpService.put(`admin/properties/${id_product}/update`, product)
    // return this.httpService.post('admin/properties/updateproduct', product)
  }

  addAdmin(product: object) : Promise<any> {
    return this.httpService.post(`admin/products-admin`, product);
  }

  getFullListPage(page: number = 1, status: string = "", search: any = "", category?:string) : Promise<any> {
    let children = null
    if(status == 'children') children = true
    if(!category){
      return this.httpService.get(`admin/products/fullist`, {page: page, status: status, search: search,children:children})
    }else{
      return this.httpService.get(`admin/products/fullist`, {page: page, status: status, search: search, id_category: category,children:children})
    }
  }

  getProductsWithProperties(page: number = 1, status: string = "", search: any = "", id_store:string): Promise<any>{
    return this.httpService.get(`admin/properties/getproductsarray`, {page: page, status: status, search: search, id_store})
  }

  getFullListPageAdmin(page: number = 1, status: string = "", search: any = "", category?:string) : Promise<any> {
    if(!category){
      return this.httpService.get(`admin/products-admin/fullist`, {page: page, status: status, search: search})
    }else{
      return this.httpService.get(`admin/products-admin/fullist`, {page: page, status: status, search: search, id_category: category})
    }
  }

  

  getProductBySku(sku: string ) : Promise<any> {
    return this.httpService.get(`admin/products/${sku}/detail`);
  }

  getProductBySkuAdmin(sku: string ) : Promise<any> {
    return this.httpService.get(`admin/products-admin/${sku}/detail`);
  }

  updateProductStatusBySku(sku: string ) : Promise<any> {
    return this.httpService.put(`admin/products/${sku}/status`);
  }

  updateProductStatusBySkuAdmin(sku: string ) : Promise<any> {
    return this.httpService.put(`admin/products-admin/${sku}/status`);
  }

  trashProduchBySku(sku: string, data ) : Promise<any> {
    return this.httpService.put(`admin/products/${sku}/trash`, data);
  }

  trashProduchBySkuAdmin(sku: string, data ) : Promise<any> {
    return this.httpService.put(`admin/products-admin/${sku}/trash`, data);
  }

  restoreProductTrashBySku(sku: string ) : Promise<any> {
    return this.httpService.put(`admin/products/${sku}/restore`);
  }

  restoreProductTrashBySkuAdmin(sku: string ) : Promise<any> {
    return this.httpService.put(`admin/products-admin/${sku}/restore`);
  }

  update(id:string, product:object){
    return this.httpService.put(`admin/products/${id}/update`, product)
  }

  updateAdmin(id:string, product:object){
    return this.httpService.put(`admin/products-admin/${id}/update`, product)
  }

  getProductWithStockList(page: number = 1, search:any = "", id_store:string) : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('id_store', String(id_store))
    .append('search', String(search))
    return this.httpService.get(`admin/products/getProductWithStockList`, params)
  }

  getProductWithStockListAdmin(page: number = 1, search:any = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('search', String(search));
    return this.httpService.get(`admin/products-admin/getProductWithStockList`, params)
  }

  getListPage(page: number = 1, search:any = "", wholesale:boolean = false, id_store,paginate:number=10) : Promise<any> {
    if(!wholesale){
      let params : HttpParams = new HttpParams()
      .append('page', String(page))
      .append('search', String(search))
      .append('paginate', String(paginate))
      .append('id_store', String(id_store));
      return this.httpService.get(`admin/properties/getproducts`, params)
    }else{
      let params : HttpParams = new HttpParams()
      .append('page', String(page))
      .append('search', String(search))
      .append('paginate', String(paginate))
      // .append('id_store', String('oopsie'));
      return this.httpService.get(`wholesales/products/list/${environment.CLIENT}`, params)
    }

  }

  updateMassive(data:object): Promise<any>{
    return this.httpService.post('admin/products/updateproducts', data)
  }

  getCustomersByProduct(data){
    return this.httpService.post('admin/statistics/customersbyproducts', data)
  }

  getProductProperties(id:string, id_store:string){
    let params : HttpParams = new HttpParams()
    .append('id_store', String(id_store))
    .append('product_sku', String(id))
    .append('product', String(id))
    return this.httpService.get('admin/properties/getproduct', params)
  }
  
  getProductPropertiesAdmin(id:string){
    let params : HttpParams = new HttpParams()
    .append('product_sku', String(id))
    .append('product', String(id))
    return this.httpService.get('admin/properties/getproductadmin', params)
  }

  getListSubs(): Promise<any>{
    return this.httpService.get('admin/subscriptions/list')
  }

}
