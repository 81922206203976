import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpService } from '../http/http.service';
import { SpinnerService } from '../spinner/spinner.service';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ConfigGeneralService } from '../configGeneral/config-general.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  file:any
  url:string = ''
  compression:string = ''
  resizeW:string = ''
  resizeH:string = ''
  resizeTw:string = ''
  resizeTh:string = ''

  private subscriptions: Array<Subscription> = [];

  constructor(
    private spinner: SpinnerService,
    private httpService: HttpService,
    private http: HttpClient,
    private configGeneral: ConfigGeneralService,
  ) {

   }

  async handleImg(event): Promise<String> {
    let nameFile = event.target.files[0].name
    if (
      nameFile.includes("jpg") ||
      nameFile.includes("png") ||
      nameFile.includes("PNG") ||
      nameFile.includes("JPG") ||
      nameFile.includes("jpeg") ||
      nameFile.includes("JPEG") ||
      nameFile.includes("pdf")
    ){
      if (event.target.files && event.target.files[0]) {
        this.file = <File>event.target.files[0];
        // this.checkArray(<File>event.target.files[0]);
        this.spinner.show()
        try {
          let res = await this.upload()
          return res
        } catch (error) {
          Swal.fire({icon: 'error', title: 'Oops...', text: 'Ha ocurrido un error'})
        }
      }
    }else{
      Swal.fire({icon: 'error', title: 'Oops...', text: 'Archivo no permitido'})
    }
  }

  async upload(): Promise<String> {
    try {
      let response = this.file.name.includes('pdf') ? await this.uploadPdf(this.file) : await this.uploadFiles(this.file)
      this.spinner.hide()
      this.url = response.data
      return this.url
    } catch (error) {
      this.spinner.hide()
      Swal.fire({icon: 'error', title: 'Oops...', text: 'Ha ocurrido un error'})
      return this.url
    }
  }

  getConfigStore(){
    
    this.subscriptions.push(
      this.configGeneral.getAllConfig()
      .subscribe((config:any[]) => {
        config.map(({name, value}) => {
          name === 'compression' && (this.compression = value)
          name === 'resize-w' && (this.resizeW = value)
          name === 'resize-t-w' && (this.resizeTw = value)
        })

      })
    )
  }

  uploadFiles(data: any) : Promise<any> {
    this.getConfigStore()

    const formData = new FormData();
    formData.append('file', data);
    formData.append('resize-w', String(this.resizeW));
    
    formData.append('resize-t-w', String(this.resizeTw));
    return this.httpService.postFile(`admin/files`, formData)
  }

  uploadPdf(data:any): Promise<any> {
    const formData = new FormData();
    formData.append('file', data);
    return this.httpService.postFile(`admin/files/file2`, formData)
  }


  downloadImgURL(url:string){
    return this.http.get(`${environment.API_URL}image-download?url=${url}`,{observe:'response',responseType:'blob'})
  }


}
