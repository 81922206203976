<header class="sticky top-0 bg-white dark:bg-dark-fondo z-20 mb-2">
    <div class="flex items-center justify-between py-2 px-4 bg-gris-claro dark:bg-dark-botonera z-50 relative">
        <div class="flex items-center gap-2 cursor-pointer" (click)="logout()">
            <!-- <h4 class="capitalize antialiased">{{url }}</h4> -->
            <a class="botones  w-full" >
                <span class="inline-flex justify-center items-center"><span class="icon-icon-5 transform rotate-180 text-xl p-1"></span></span>
                <!-- <span class="ml-2 text-sm tracking-wide truncate textNav">{{'users.logout' | translate}}</span> -->
            </a>
            <span [ngClass]="toggleService.comprimida ? 'flex' : 'hidden sm:flex '">{{'users.logout' | translate}}</span>
        </div>
        
        <nav aria-label="Secondary" class="space-x-2 flex items-center">
            <button  *ngIf="role !== 'master_franchise'" (click)="redirectURL('/estadisticas')" [ngClass]="[toggleService.comprimida ? 'hidden' : 'flex ',buttonActive == '/estadisticas' ? 'bg-gris-claro-hover cursor-default':'hover:bg-gris-claro-hover hover:bg-opacity-40']" class="py-1 px-2 items-center transition-colors duration-200 rounded-lg border-2 border-gris-claro-hover">
                {{'statistics.title' | translate}} 
            </button>
            <button  *ngIf="role !== 'master_franchise'" (click)="redirectURL('/menupdf')" [ngClass]="[toggleService.comprimida ? 'hidden' : 'flex ',buttonActive == '/menupdf' ? 'bg-gris-claro-hover cursor-default':'hover:bg-gris-claro-hover hover:bg-opacity-40']"class="py-1 px-2 items-center transition-colors duration-200 rounded-lg border-2 border-gris-claro-hover">
                Menu PDF
            </button>
            <div class="group" [ngClass]="toggleService.comprimida ? ' inline-block' : 'hidden md:inline-block '">
                <button class="p-1 flex items-center transition-colors duration-200 hover:text-principal">
                <span><img class="w-5" [src]="defaultLn.flag" alt=""></span>
                <span *ngIf="false" class="text-xs md:text-sm pl-2 m-auto"> {{defaultLn.lan}} </span>
                <span class="icon-angle-down text-md md:text-lg pl-2"></span>
              </button>
                <ul class="bg-white dark:bg-dark-fondo border rounded-sm transform scale-0 group-hover:scale-100 absolute
                transition duration-150 ease-in-out origin-top min-w-30">
                    <li (click)="changeLanguaje(flag)" *ngFor="let flag of languajes" class="rounded-sm px-1 md:px-3 md:py-1 hover:text-principal">
                        <ng-container *ngIf="flag.lan !== defaultLn.lan">
                            <button class="p-1 flex items-center transition-colors duration-200 hover:text-principal">
                            <span><img class="w-3 md:w-5" [src]="flag.flag" alt=""></span>
                            <span class="text-xs md:text-sm pl-2 m-auto"> {{flag.lan}} </span>
                            </button>
                        </ng-container>
                    </li>
                </ul>
            </div>
            <button *ngIf="role === 'store'" routerLink="/myAccount" class="p-1 flex items-center transition-colors duration-200 hover:text-principal">
                <span class="icon-edit-user text-xl sm:text-2xl"></span>
                <span *ngIf="false" class="hidden sm:inline-block text-sm pl-2 m-auto"> {{'account.miCuenta' | translate}} </span>
            </button>
            <button class="w-8 h-8 transition-all duration-300 group relative m-1" (click)="toggleService.toggle()">
                <span class="w-6 h-0.5 bg-gris-base group-hover:bg-principal group-focus:bg-principal transition-all duration-100 absolute left-1/2 -translate-x-1/2 transform origin-center" [ngClass]="toggleService.comprimida ? 'top-2':'opacity-0'"></span>
                <span class="w-6 h-0.5 bg-gris-base group-hover:bg-principal group-focus:bg-principal transition-all duration-300 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform origin-center" [ngClass]="toggleService.comprimida ? '':' top-1/2 -translate-y-1/2 rotate-45'"></span>
                <span class="w-6 h-0.5 bg-gris-base group-hover:bg-principal group-focus:bg-principal transition-all duration-300 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform origin-center" [ngClass]="toggleService.comprimida ? '':'top-1/2 -translate-y-1/2 -rotate-45'"></span>
                <span class="w-6 h-0.5 bg-gris-base group-hover:bg-principal group-focus:bg-principal transition-all duration-100 absolute left-1/2 -translate-x-1/2 transform origin-center" [ngClass]="toggleService.comprimida ? 'bottom-2':'opacity-0'"></span>
            </button>
            <!-- 
            <button class="w-8 h-8 transition-colors duration-200 hover:text-principal" [ngClass]="toggleService.comprimida ? 'relative m-1':'fixed md:relative left-64 md:left-0 top-0 px-4 py-7 md:px-0 md:py-0 bg-white dark:bg-dark-botonera md:bg-transparent'" (click)="toggleService.toggle()">
                <span class="w-6 h-0.5 bg-gris-base absolute left-1/2 -translate-x-1/2 transform origin-center" [ngClass]="toggleService.comprimida ? 'top-2':' top-1/2 -translate-y-1/2 rotate-45'"></span>
                <span class="w-6 h-0.5 bg-gris-base absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform origin-center" [ngClass]="toggleService.comprimida ? '':'hidden'"></span>
                <span class="w-6 h-0.5 bg-gris-base absolute left-1/2 -translate-x-1/2 transform origin-center" [ngClass]="toggleService.comprimida ? 'bottom-2':' top-1/2 -translate-y-1/2 -rotate-45'"></span>
            </button>
             -->
        </nav>
    </div>
    <!-- sidebar -->
        <app-sidebar></app-sidebar>
    <!-- fin sidebar -->
</header>