import { Component, OnInit,ElementRef, ViewChild, Input } from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';
import { StorageService } from 'src/app/service/storage/storage.service';
import { ROUTES } from './sidebar-menu-routes.config';
import { ConfigurationService } from 'src/app/service/configuration/configuration.service';
import { ConfigGeneralService } from 'src/app/service/configGeneral/config-general.service';
import { ToggleClassService } from './../../service/toggle/toggle-class.service';

import { Clipboard } from '@angular/cdk/clipboard';
import { AlertsService } from 'src/app/service/alerts/alerts.service';
import { TranslateService } from '@ngx-translate/core';

import { FilesService } from 'src/app/service/files/files.service';
import { SpinnerService } from 'src/app/service/spinner/spinner.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: any[] = []
  level: number = 0
  role:string = ''
  storeID:string = ''
  store:any = {}
  ObjectKeys = Object.keys
  selected: any = {};
  list:any[] = []
  LOGO_DEFAULT:string = './assets/img/logo_icon.png'
  // LOGO_DEFAULT:string = 'https://www.flexystore.com/images/img-que-es.png'
  LOGO_PERSONALIZED:string = './assets/img/logo_icon.png'
  
  @ViewChild('menuPreselect') menuPreselect: ElementRef<HTMLElement>;
  rol:string = ''
  //isMobile:boolean
  @Input() isMobile:boolean

  domainText:string = ''
  private subscriptions: Array<Subscription> = [];
  buttonActive:string = ''

  
  constructor(
    public toggleService: ToggleClassService,
    private storageService : StorageService,
    private authService: AuthService,
    private router: Router,
    private configurationService: ConfigurationService,
    private clpb: Clipboard,
    private alerts: AlertsService,
    private translate: TranslateService,
    private fileService: FilesService,
    private spinner: SpinnerService,

    private ConfigGeneralService: ConfigGeneralService

  ) {

    this.storageService.getRole()
    .subscribe((role: string) => {
      if(role) {
        this.role = role;
      }
    });
    
    if (localStorage.getItem('store') === undefined || localStorage.getItem('store') === "null") {
      this.storeID ="";
    }else
    {
      this.storeID = JSON.parse(localStorage.getItem('store') || '').id;
      this.store = JSON.parse(localStorage.getItem('store'))
      this.domainText = this.store.domain.replace('https://','')
    }
    this.getButtonActive()
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  copyURL(){
    this.clpb.copy(this.store.domain);
    this.alerts.alertSuccess(this.translate.instant('shares.alerts.url'))
  }

  downloadQR(){
    this.spinner.show()
    this.fileService.downloadImgURL(this.store.qr)
    .subscribe(response =>{
      let fileName = `QRCode_${this.store.id}`
      let blob:Blob = response.body as Blob;
      let a = document.createElement('a')
      a.download = fileName
      a.href = window.URL.createObjectURL(blob)
      a.click()
      this.spinner.hide()
    })
  }

  getButtonActive(){
    this.subscriptions.push(
      this.toggleService.getButtonActive().subscribe(btnActive => {
        this.buttonActive = btnActive
      } )
    )   
  }

  ngOnInit(): void {
    let menu= ROUTES
    menu = menu.filter(item =>  item.role.includes(this.role.replace(/['"]+/g, '')))
    menu.map(e => {
      const result:any[] = e.tienda.filter((aux:any) => { return (aux === this.storeID.replace(/['"]+/g, '') && aux == 'morumbi') || aux === 'all' })
      e.submenu.map(sub => {
        const result2:any[] = sub.role.filter(role => role === this.role.replace(/['"]+/g, ''))
        if(result2.length > 0) sub.badgeClass = 'show'
        else sub.badgeClass = 'notShow'
      })
      if(result.length > 0) e.badgeClass = 'show'
      else e.badgeClass = 'notShow'
    })

    this.selected = menu[2]
    this.select('main', 'stores.my_title')
    if(this.storeID != ''){
        this.configurationService.getListByStore(this.storeID)
        .then(res => {
          this.list = res.response
          let show
          let state = true
          let exist = true

          
          let boolsShow = { 
            clients: true,
            whatsapp: true,
            facebook: true,
            instagram: true,
            etiquetas: true,
            artistas: true,
            toppings: true,
            zones:true
          }
          this.list.map(item => {
            if(item.name == 'hide-mensajeria'){
              show = item.value == null || item.value == false ? false : true
            }
            if(item.name === 'add-products'){
              state = item.value.toLowerCase() === 'true'
              exist = true
            }
            if(item.name == 'clients' || item.name == 'user-statistics'){
              switch(item.value){
                case 'root':
                case 'admin':
                  if(item.value == this.role.replace(/['"]+/g, '')){
                    boolsShow.clients = true
                  }
                  else {
                    boolsShow.clients = false
                  }
                break;
                case 'user':
                case 'store':
                  boolsShow.clients = true
                break;
              }

            }
            if(item.name == 'hide-tags'){
              boolsShow.etiquetas = item.value.toLowerCase() === 'true'
            }
            if(item.name == 'hide-artists'){
              boolsShow.artistas = item.value.toLowerCase() === 'true'
            }
            if(item.name == 'hide-toppings'){
              boolsShow.toppings = item.value.toLowerCase() === 'true'
            }
            if(item.name == 'hide-zones'){
              boolsShow.zones = item.value.toLowerCase() === 'true'
            }
            if(item.name == 'share-whatsapp'){
              boolsShow.whatsapp = item.value.toLowerCase() === 'true'
            }
            if(item.name == 'share-facebook'){
              boolsShow.facebook = item.value.toLowerCase() === 'true'
            }
            if(item.name == 'share-instagram'){
              boolsShow.instagram = item.value.toLowerCase() === 'true'
            }
          })
          // console.log('menu',menu);
          // console.log('mostrar',boolsShow.instagram);

          menu = menu.map(item=>{
            
            if(item.title == 'Mensajeria' || item.title == 'campaign'){
              item.show = show
            }
            else{
              item.show = true
            }
            
            if(item.title == 'customers.title'){
              item.show = boolsShow.clients
            }
            if(item.title == 'statistics.title'){
              item.show = boolsShow.clients
            }
            if(item.title == 'Envios'){
              item.show = !boolsShow.zones
            }

            if(item.title == 'products.title' && exist){
              if(state == false){
                item.submenu = item.submenu.filter(val =>  val.title != 'create-product.title')
              }
              if(boolsShow.etiquetas == true){
                item.submenu = item.submenu.filter(val => val.title != 'etiquetas.title')
              }
              if(boolsShow.toppings == true){
                item.submenu = item.submenu.filter(val => val.title != 'Toppings')
              }
              if(boolsShow.artistas == true){
                item.submenu = item.submenu.filter(val => val.title != 'artists.title')
              }
            }

            if(item.title == 'products.compartir'){
              if(boolsShow.whatsapp == false){
                item.submenu = item.submenu.filter(val =>  val.title != 'Whatsapp')
              }
              if(boolsShow.facebook == false){
                item.submenu = item.submenu.filter(val =>  val.title != 'Facebook')
              }
              if(boolsShow.instagram == false){
                item.submenu = item.submenu.filter(val =>  val.title != 'Instagram')
              }
            }
            
            return item
          })
          this.menuItems = menu.filter(item => item.show || item.show == undefined)
         

        })
        .catch(err => {
          console.log(err)
        })
     
    }
    else{
      this.menuItems = menu
    }
    // console.log(this.menuItems)
    this.getLogo()
  }


  getLogo(){
    this.ConfigGeneralService.getList()
    .then(res => {
      // console.log('respuesta',res.response);
      let listado = res.response
      listado.map(item=>{
        if(item.name === 'img-logo-bo'){
          this.LOGO_PERSONALIZED = item.value != '' && item.value != null ? item.value : this.LOGO_DEFAULT
        }
      })
    })
    .catch(err => {
          console.log(err)
    })
  }


  showLength(item){
    let contador = 0
    item.map(val=>{
      let roles = Object.values(val.role)
      let cant = roles.filter(it => it == this.role.replace(/['"]+/g, ''))
      cant.length == 1 ? contador ++ : null

    })

    return contador
  }



  selectItem(item){
    var element = document.querySelector(item);
    // element.classList.toggle("active");
    // var elementos = document.querySelectorAll('.active')
    // elementos.forEach(element =>  element.classList.remove('active') )
    var paneles = document.querySelectorAll('.panel')
    paneles.forEach(element =>  {element.classList.remove('border-b'),element.setAttribute("style", "null")} )
  }

  toggleAccordian(data:any) {
    var element = document.querySelector(data);
    // var elementos = document.querySelectorAll('.active')
    var paneles = document.querySelectorAll('.panel')
    var panel = element.nextElementSibling;
    // elementos.forEach(element =>  element.classList.remove('active') )
    paneles.forEach(element =>  {element.classList.remove('border-b');element.setAttribute("style", "null")} )
    element.isActive = !element.isActive;
    //element.classList.toggle("active");

    if(element.isActive) {
      // element.classList.add('active')
      panel.style.maxHeight = panel.scrollHeight + "px";
      panel.classList.add('border-b');
    } else {
      // element.classList.remove('active')
      panel.style.maxHeight = null;
      panel.classList.remove('border-b');
    }

  }

  logout(){
    this.authService.doLogout();
    this.router.navigate(['']);
  }

  select(type, item, $event?) {
    this.selected[type] = (this.selected[type] === item ? null : item);
    $event ? $event.stopPropagation() : null;
  }

  isActive(type, item) {
    return this.selected[type] === item;
  }

  redirect(sub:boolean, item){
    this.toggleService.setButtonActive(item.path)
    item.submenu.length === 0 && (this.router.navigate([`/${item.path}`]))
  }


}
